<template>
    <div class="about">
        <p>me [at] skon.dev</p>
    </div>
</template>

<script>
export default {
    name: 'AboutMe',
}
</script>

<style scoped>
.about {
    text-align: center;
    color: #D4ECDD;
    font-family: 'Courier Prime', monospace;
    font-weight: 300;
    font-size: 20px;
    line-height: .8;
}
</style>